import VueLoadImage from "vue-load-image";
import { IMAGES } from "@assets";
import { ScreenLoading } from "@components";
import { egiftExchangeCodeService } from "@services";
import { LANGUAGES } from "@i18n";
import {
    HTTP_CODE_EGIFT_EXCHANGE_CODE_NOT_EXIST,
    HTTP_CODE_EGIFT_EXCEED_LIMIT_EXCHANGE_CODE,
    HTTP_CODE_UNPROCESSABLE_ENTITY,
    HTTP_CODE_BAD_REQUEST,
    MINIMUM_TIMEOUT_LOADING
} from "@constants";

export default {
    name: "EgiftExchangeCode",
    components: {
        ScreenLoading,
        "vue-load-image": VueLoadImage
    },
    data() {
        return {
            IMAGES,
            isFetchingPage: false,
            settings: {},
            exchangeCode: "",
            isNotClient: false,
            invalidExchangeCode: false,
            exceedLimitExchangeCode: false,
            containerHeight: 0,
            containerWidth: 530
        };
    },
    methods: {
        getEgiftExchangeCodeSetting: async function() {
            // Loading screen is on
            this.isFetchingPage = true;

            const { data } = await egiftExchangeCodeService.getSettings(
                this.$route.params.slug_name
            );

            if (data.btn_color || data.logo_url) {
                this.settings = data;

                // Set color for button view voucher
                document.documentElement.style.setProperty(
                    "--view-voucher-btn",
                    data.btn_color
                );

                // Set gift card language
                this.$i18n.locale = LANGUAGES[data.default_language_id];

                // Start render
                this.isFetchingPage = false;

                this.isNotClient = false;
            } else {
                // Start render
                this.isFetchingPage = false;

                this.isNotClient = true;
            }
        },
        makeExchangeCode: async function() {
            // Loading screen is on
            this.isFetchingPage = true;

            const result = await egiftExchangeCodeService.exchangingCode(
                this.$route.params.slug_name,
                this.exchangeCode
            );

            if (result) {
                const statusCode = result.status;

                // If Exchange code not match will show error message
                if (
                    statusCode == HTTP_CODE_EGIFT_EXCHANGE_CODE_NOT_EXIST ||
                    statusCode == HTTP_CODE_BAD_REQUEST ||
                    statusCode == HTTP_CODE_UNPROCESSABLE_ENTITY ||
                    statusCode == HTTP_CODE_EGIFT_EXCEED_LIMIT_EXCHANGE_CODE
                ) {
                    setTimeout(
                        () => (
                            // Start render
                            (this.isFetchingPage = false),
                            (this.handleShowError(statusCode))
                        ),
                        MINIMUM_TIMEOUT_LOADING
                    );
                }
            }
        },
        filterInput(e) {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]+/g, "");
            this.exchangeCode = e.target.value;
        },
        handleBackBtn() {
            this.invalidExchangeCode = false;
            this.exceedLimitExchangeCode = false;
        },
        handleShowError(statusCode) {
            if (
                statusCode == HTTP_CODE_EGIFT_EXCHANGE_CODE_NOT_EXIST ||
                statusCode == HTTP_CODE_BAD_REQUEST ||
                statusCode == HTTP_CODE_UNPROCESSABLE_ENTITY
            ) {
                this.invalidExchangeCode = true;
            } else if (statusCode == HTTP_CODE_EGIFT_EXCEED_LIMIT_EXCHANGE_CODE) {
                this.exceedLimitExchangeCode = true;
            }
        },
    },
    computed: {
        displayFormInputEgiftExchange: function () {
            return !this.isNotClient && !this.invalidExchangeCode && !this.exceedLimitExchangeCode;
        },
        imageStyle() {
            // Calculate the width and height to maintain aspect ratio
            const aspectRatio = 16 / 9; // image aspect ratio
            let width = this.containerWidth;
            let height = this.containerWidth / aspectRatio;

            // Check if the calculated height exceeds the container height
            if (height > this.containerHeight) {
                height = this.containerHeight;
                width = this.containerHeight * aspectRatio;
            }

            return {
                width: `${width}px`, // Set the calculated width
                height: `${height}px`, // Set the calculated height
                objectFit: 'contain',
            };
        },
    },
    created() {
        // Fetch data
        this.getEgiftExchangeCodeSetting();
        // Set the container height to match the screen size
        this.containerHeight = window.innerHeight / 4;
    }
};
